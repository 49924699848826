<template>
  <div class="tooltip" >
    <slot name="hover-item" />
    <span
      :class="['tooltip__content', setPosition]"
      :style="{
        letterSpacing: tenantStyleGuide.global.letterSpacing,
        fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
        color: tenantStyleGuide.content.fontPrimaryLight
      }"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
import DefaultTheme from '../../theme/default.json'

export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      default: '',
      require: false
    },
    position: {
      type: String,
      default: 'bottom',
      require: false
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  computed: {
    setPosition () {
      return `tooltip__content--${this.position}`
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/_fonts"

.tooltip
  position: relative
  display: inline-block

  &__content
    visibility: hidden
    max-width: 400px
    min-width: 200px
    background-color: rgba(0, 0, 0, 0.8)
    text-align: center
    border-radius: 6px
    padding: 10px 5px
    font-size: 12px
    position: absolute
    z-index: 1

    &--top
      bottom: 125%

    &--bottom
      top: 25px
      right: 100%

    &--right
      top: -25px
      right: 120%

    &--left
      top: -25px
      left: 120%

  &:hover
    cursor: pointer

  &:hover .tooltip__content
    visibility: visible

</style>
