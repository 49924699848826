<template>
  <div class="container">
    <div class="statusCard" :style="{ background: tenantStyleGuide.content.boxBackground, border: tenantStyleGuide.content.boxBorder, borderRadius: tenantStyleGuide.wallet.walletHeaderBorderRadius}" >
      <div class="statusContainer" :style="{ background: userStatusBackground, padding: tenantStyleGuide.wallet.walletStatusPadding}">
        <div class="statusText" :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.global.fontFamilyBase, color: tenantStyleGuide.content.fontPrimaryLight }">
          {{ userStatusMessage }}
        </div>
        <Tooltip :text="userStatusSupportMessage" :position="tooltipPosition" :tenant-style-guide="tenantStyleGuide">
          <template v-slot:hover-item>
            <div class="statusCard__item--icon-color statusTag" v-html="svgIcon(tenantStyleGuide.icons.info)" :style="{ fill: tenantStyleGuide.content.fontPrimaryLight }" />
          </template>
        </Tooltip>
      </div>

      <div class="statusCard__item" :style="logoPosition">
        <div class="statusCard__item--icon" :style="{ color: tenantStyleGuide.status.confirmed, background: tenantStyleGuide.status.confirmedLight, border: tenantStyleGuide.tags.borderConfirmed }">
          <div class="statusCard__item--icon-color" v-html="svgIcon(tenantStyleGuide.icons.cashback)" :style="{ fill: tenantStyleGuide.status.confirmed, width: tenantStyleGuide.tags.iconSize, height: tenantStyleGuide.tags.iconSize }" />
        </div>
        <div class="statusCard__item--text">
          <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.global.fontFamilyBase, color: tenantStyleGuide.content.fontPrimary, fontWeight: tenantStyleGuide.wallet.fontWeight }"> {{ tenantMessages.walletHeader.walletTitles.confirmed.title }} </p>
          <h2 :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.status.confirmed }"> {{ currencyFormatter(balanceInCents)}} </h2>
        </div>
      </div>

      <div class="statusCard__divider" :style="{ border: tenantStyleGuide.content.boxBorder }" />

      <div class="statusCard__item" :style="logoPosition">
        <div class="statusCard__item--icon" :style="{ color: tenantStyleGuide.statusOpen, background: tenantStyleGuide.status.pendingLight, border: tenantStyleGuide.tags.borderPending }">
          <div class="statusCard__item--icon-color">
           <div class="statusCard__item--icon-color" v-html="svgIcon(tenantStyleGuide.icons.cashbackIn)" :style="{ fill: tenantStyleGuide.status.pending, width: tenantStyleGuide.tags.iconSize, height: tenantStyleGuide.tags.iconSize}" />
          </div>
        </div>
        <div class="statusCard__item--text">
          <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.global.fontFamilyBase, color: tenantStyleGuide.content.fontPrimary, fontWeight: tenantStyleGuide.wallet.fontWeight }"> {{ tenantMessages.walletHeader.walletTitles.pending.title }} </p>
          <h2 :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }"> {{ currencyFormatter(pendingBalanceInCents)}} </h2>
        </div>
      </div>

     <div class="statusCard__divider" :style="{ border: tenantStyleGuide.content.boxBorder }" />

      <div class="statusCard__item" :style="logoPosition">
        <div class="statusCard__item--icon" :style="{ color: tenantStyleGuide.status.expired, background: tenantStyleGuide.status.expiredLight, border: tenantStyleGuide.tags.borderCanceled}">
          <div class="statusCard__item--icon-color">
            <div class="statusCard__item--icon-color" v-html="svgIcon(tenantStyleGuide.icons.cashbackOut)" :style="{ fill: tenantStyleGuide.status.expired, width: tenantStyleGuide.tags.iconSize, height: tenantStyleGuide.tags.iconSize }" />
          </div>
        </div>
        <div class="statusCard__item--text">
          <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.global.fontFamilyBase, color: tenantStyleGuide.content.fontPrimary, fontWeight: tenantStyleGuide.wallet.fontWeight }"> {{ tenantMessages.walletHeader.walletTitles.expire.title }} </p>
          <h2 :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary, color: tenantStyleGuide.content.fontPrimary }"> {{ currencyFormatter(expirationBalanceInCents)}} </h2>
          <p :style="{ letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.global.fontFamilyBase, color: tenantStyleGuide.content.fontPrimary, fontWeight: tenantStyleGuide.wallet.fontWeight}"> {{ tenantMessages.walletHeader.walletTitles.expire.subtitle }} </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import convertBase64 from '../../utils/base64'
import currencyFormatter from '../../utils/formatCurrency'

import DefaultTheme from '../../theme/default.json'
import DefaultMessages from '../../messages/default.json'

import Tooltip from '../../components/Tooltip'

export default {
  name: 'WalletHeader',
  props: {
    userStatus: {
      type: String,
      require: false,
      default: 'active'
    },
    balanceInCents: {
      type: Number,
      require: false,
      default: 0
    },
    pendingBalanceInCents: {
      type: Number,
      require: false,
      default: 0
    },
    expirationBalanceInCents: {
      type: Number,
      require: false,
      default: 0
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  components: {
    Tooltip
  },
  data () {
    return {
      tenantMessages: {}
    }
  },
  created () {
    this.tenantMessages = DefaultMessages
  },
  computed: {
    userStatusBackground () {
      let statusBackground = this.tenantStyleGuide.feedback.success
      if (this.userStatus === 'inactive') {
        statusBackground = this.tenantStyleGuide.feedback.warning
      } else if (this.userStatus === 'blocked') {
        statusBackground = this.tenantStyleGuide.feedback.error
      } else {
        statusBackground = this.tenantStyleGuide.feedback.success
      }

      return statusBackground
    },
    tooltipPosition () {
      let position = 'top'
      if (document.documentElement.clientWidth < 991.98) {
        position = 'bottom'
      } else {
        position = 'top'
      }
      return position
    },
    logoPosition () {
      let position = ''
      if (this.tenantStyleGuide.wallet.walletLogoPositionMobile === 'left') {
        if (document.documentElement.clientWidth < 991.98 && document.documentElement.clientWidth > 767.98) {
          position = { justifyContent: this.tenantStyleGuide.wallet.walletLogoPositionMobile, margin: '20px 20px 20px 35%' }
        } else if (document.documentElement.clientWidth < 767.98) {
          position = { justifyContent: this.tenantStyleGuide.wallet.walletLogoPositionMobile }
        }
      } else if (document.documentElement.clientWidth < 991.98 && this.tenantStyleGuide.wallet.walletLogoPositionMobile === 'center') {
        position = { justifyContent: 'center' }
      }
      return position
    },
    userStatusMessage () {
      let statusMessage = this.tenantMessages.walletHeader.status.active.title
      if (this.userStatus === 'inactive') {
        statusMessage = this.tenantMessages.walletHeader.status.inactive.title
      } else if (this.userStatus === 'blocked') {
        statusMessage = this.tenantMessages.walletHeader.status.blocked.title
      } else {
        statusMessage = this.tenantMessages.walletHeader.status.active.title
      }
      return statusMessage
    },
    userStatusSupportMessage () {
      let statusSupportMessage = this.tenantMessages.walletHeader.status.active.supportText
      if (this.userStatus === 'inactive') {
        statusSupportMessage = this.tenantMessages.walletHeader.status.inactive.supportText
      } else if (this.userStatus === 'blocked') {
        statusSupportMessage = this.tenantMessages.walletHeader.status.blocked.supportText
      } else {
        statusSupportMessage = this.tenantMessages.walletHeader.status.active.supportText
      }
      return statusSupportMessage
    }
  },
  methods: {
    svgIcon (value) {
      return convertBase64(value)
    },
    currencyFormatter (value) {
      return currencyFormatter.formatInReais(value / 100)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/_mixins"
@import "../../assets/_fonts"

.container
  @include display-row
  margin: auto
  width: 100%
  @include tablets-and-down
    width: 99%
    flex-direction: column

.statusContainer
  @include display-col
  justify-content: flex-end
  @include tablets-and-down
    flex-direction: row
    justify-content: center
    align-items: center

  & svg path
    fill: currentcolor

.statusText
  margin-bottom: 10px
  writing-mode: vertical-rl
  transform: rotate(180deg)
  display: flex
  align-items: center
  @include tablets-and-down
    margin-bottom: 0px
    margin-right: 10px
    writing-mode: horizontal-tb
    transform: none

.statusCard
  @include display-row
  justify-content: center
  flex-wrap: wrap
  width: 100%
  overflow: hidden
  @include tablets-and-down
    @include display-col

  & h3
    font-size: 20px

  & p
    font-size: 12px

  &__item
    @include display-row
    align-items: center
    justify-content: center
    margin: auto

    &--icon
      width: 46px
      height: 46px
      display: flex
      justify-content: center
      margin-right: 20px
      align-items: center
      border-radius: 100%

      &-color
        display: flex
        align-items: center

        & svg path
          fill: currentcolor

    &--text
      max-width: 70%

      & h2
        font-size: 30px
        line-height: 30px
        margin: 5px 0
        @include tablets-and-down
          font-size: 25px
          overflow: hidden
          white-space: nowrap
          text-overflow: ellipsis

      & p
        margin: 2px 0

  &__divider
    width: 0
    height: 100%
    margin: 0 10px
    @include tablets-and-down
      width: 100%
      height: 0px
      margin: 0

.statusTag
  text-align: center
  @include tablets-and-down
    text-align: left
    display: flex
    align-items: center

</style>
