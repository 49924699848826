import request from '../utils/request'

export function getWallet (externalRefId, apiKey) {
  return request({
    url: `/digital-wallets/${externalRefId}`,
    method: 'get',
    headers: {
      'x-api-key': apiKey
    }
  })
}

export function getCashbackExpirationIn30Days (externalRefId, apiKey) {
  return request({
    url: `/cashback-expirations/expire-in-30-days/${externalRefId}`,
    method: 'get',
    headers: {
      'x-api-key': apiKey
    }
  })
}

export default {
  getWallet,
  getCashbackExpirationIn30Days
}
