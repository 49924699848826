<template>
  <div class="pagination" :style="{ background: tenantStyleGuide.content.boxBackground, border: tenantStyleGuide.content.boxBorder, borderRadius: tenantStyleGuide.content.boxBorderRadius }">
    <div>
      <select
        class="pagination__perPage"
        :style="{
          border: tenantStyleGuide.content.boxBorder,
          letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary
        }"
        @change="$emit('changePerPage', $event.target.value)"
      >
        <option value="5">5</option>
        <option value="10" selected>10</option>
        <option value="15">15</option>
      </select>
    </div>
    <div
      :style="{
        letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
        display: 'flex',
        alignItems: 'center'
      }"
    >
    <span style="margin-right: 10px;">
      {{ currentPage }} - {{ totalPages }}
    </span>
    <button
      id="previewsPage"
      class="pagination__button"
      :style="{ background: tenantStyleGuide.content.buttonBackgroundActive }"
      @click="handlePreviewsPage"
    >
      <div  class="pagination__button-icon" v-html="svgIcon(tenantStyleGuide.icons.previews)" :style="{ fill: tenantStyleGuide.content.fontPrimaryLight }" />
    </button>
    <button
      id="nextPage"
      class="pagination__button"
      :style="{ background: tenantStyleGuide.content.buttonBackgroundActive}"
      @click="handleNextPage"
    >
      <div  class="pagination__button-icon" v-html="svgIcon(tenantStyleGuide.icons.next)" :style="{ fill: tenantStyleGuide.content.fontPrimaryLight }" />
    </button>
    </div>
  </div>
</template>

<script>
import convertBase64 from '../../utils/base64'

import DefaultTheme from '../../theme/default.json'

export default {
  name: 'Pagination',
  emits: ['changePage', 'changePerPage'],
  props: {
    currentPage: {
      type: Number,
      default: 1,
      require: false
    },
    perPage: {
      type: Number,
      default: 10,
      require: false
    },
    totalEntries: {
      type: Number,
      default: 0,
      require: false
    },
    totalPages: {
      type: Number,
      default: 0,
      require: false
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  data () {
    return {
      page: 1
    }
  },
  methods: {
    handlePreviewsPage () {
      if (this.page !== 1) {
        this.page--
        this.$emit('changePage', this.page)
      }
    },
    handleNextPage () {
      if (this.page < this.totalPages) {
        this.page++
        this.$emit('changePage', this.page)
      }
    },
    svgIcon (value) {
      return convertBase64(value)
    }

  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/_mixins"

.pagination
  padding: 16px
  @include display-row
  justify-content: space-between
  align-items: center
  overflow-x: auto

  &__perPage
    background: #FFF
    border-radius: 10px
    padding: 10px 5px

  &__button
    cursor: pointer
    border: none
    width: 40px
    align-items: center
    padding: 5px 15px
    border-radius: 10px
    margin: 5px

    &-color
      width: 5px

      & svg path
        width: 5px
        fill: currentcolor

</style>
