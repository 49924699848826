<template>
  <div class="tab">
    <div
      class="tab__header"
      :style="{
        border: handleBorderBox,
        letterSpacing: tenantStyleGuide.content.buttonLetterSpacing,
        fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
        borderRadius: tenantStyleGuide.content.boxBorderRadius,
        color: tenantStyleGuide.content.fontPrimary,
        fontWeight: tenantStyleGuide.content.buttonFontWeight
      }"
    >
      <button
        v-for="tab in tabsList"
        :id="tab.value !== '' ? tab.value : 'all'"
        :key="tab.title"
        :value="tab.value"
        class="tab__header--button"
        @mouseover="mouseIn"
        @mouseleave="mouseOut"
        @click="onClick"
        :style="{
          letterSpacing: tenantStyleGuide.content.buttonLetterSpacing,
          fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
          color: tenantStyleGuide.content.fontPrimary,
          textTransform: tenantStyleGuide.content.buttonTextTransform,
          border: tenantStyleGuide.content.buttonBorder,
          fontWeight: tenantStyleGuide.content.buttonFontWeight,
          padding: tenantStyleGuide.content.buttonPadding || '10px'
        }"
      >
        {{ tab.title}}
      </button>
    </div>
  </div>
</template>

<script>
import DefaultTheme from '../../theme/default.json'

export default {
  name: 'Tabs',
  emits: ['activeTab'],
  props: {
    tabsList: {
      type: Array,
      default: () => ([]),
      require: false
    },
    tenantStyleGuide: {
      type: Object,
      require: true,
      default: DefaultTheme
    }
  },
  data () {
    return {
      active: ''
    }
  },
  mounted () {
    this.activeFirst(this.tabsList[0].value !== '' ? this.tabsList[0].value : 'all')
    this.checkTheme()
  },
  computed: {
    handleBorderBox () {
      if (this.tenantStyleGuide.content.buttonBorder !== 'none') {
        return 'none'
      }

      return this.tenantStyleGuide.content.boxBorder
    }
  },
  methods: {
    checkTheme () {
      setTimeout(() => {
        this.activeFirst(this.tabsList[0].value !== '' ? this.tabsList[0].value : 'all')
        this.loading = false
      }, 500)
    },
    activeFirst (value) {
      try {
        document.getElementById(value).style = `background: ${this.tenantStyleGuide.content.buttonBackgroundActive}; color: ${this.tenantStyleGuide.content.buttonFontActive}; font-family: ${this.tenantStyleGuide.content.fontFamilyPrimary}; letter-spacing: ${this.tenantStyleGuide.content.buttonLetterSpacing}; text-transform: ${this.tenantStyleGuide.content.buttonTextTransform}; border: ${this.tenantStyleGuide.content.buttonBorder}; font-weight: ${this.tenantStyleGuide.content.buttonFontWeight}; padding: ${this.tenantStyleGuide.content.buttonPadding || '10px'};`
        this.active = value
      } catch (error) {
        return false
      }
    },
    mouseIn (event) {
      if (event.target.id !== this.active) {
        document.getElementById(event.target.id).style = `background: ${this.tenantStyleGuide.content.buttonBackgroundHover}; color: ${this.tenantStyleGuide.content.buttonFontHover}; font-family: ${this.tenantStyleGuide.content.fontFamilyPrimary}; letter-spacing: ${this.tenantStyleGuide.content.buttonLetterSpacing}; text-transform: ${this.tenantStyleGuide.content.buttonTextTransform}; border: ${this.tenantStyleGuide.content.buttonBorder}; font-weight: ${this.tenantStyleGuide.content.buttonFontWeight}; padding: ${this.tenantStyleGuide.content.buttonPadding || '10px'};`
      }
    },
    mouseOut (event) {
      if (event.target.id !== this.active) {
        document.getElementById(event.target.id).style = `background: ${this.tenantStyleGuide.content.buttonBackgroundInactive}; color: ${this.tenantStyleGuide.content.fontPrimary}; font-family: ${this.tenantStyleGuide.content.fontFamilyPrimary}; letter-spacing: ${this.tenantStyleGuide.content.buttonLetterSpacing}; text-transform: ${this.tenantStyleGuide.content.buttonTextTransform}; border: ${this.tenantStyleGuide.content.buttonBorder}; font-weight: ${this.tenantStyleGuide.content.buttonFontWeight}; padding: ${this.tenantStyleGuide.content.buttonPadding || '10px'};`
      }
    },
    onClick (event) {
      if (this.active !== event.target.id && this.active !== '') {
        document.getElementById(this.active).style = `background: ${this.tenantStyleGuide.content.buttonBackgroundInactive}; color: ${this.tenantStyleGuide.content.fontPrimary}; font-family: ${this.tenantStyleGuide.content.fontFamilyPrimary}; letter-spacing: ${this.tenantStyleGuide.content.buttonLetterSpacing}; text-transform: ${this.tenantStyleGuide.content.buttonTextTransform}; border: ${this.tenantStyleGuide.content.buttonBorder}; font-weight: ${this.tenantStyleGuide.content.buttonFontWeight}; padding: ${this.tenantStyleGuide.content.buttonPadding || '10px'};`
        document.getElementById(event.target.id).style = `background: ${this.tenantStyleGuide.content.buttonBackgroundActive}; color: ${this.tenantStyleGuide.content.buttonFontActive}; font-family: ${this.tenantStyleGuide.content.fontFamilyPrimary}; letter-spacing: ${this.tenantStyleGuide.content.buttonLetterSpacing}; text-transform: ${this.tenantStyleGuide.content.buttonTextTransform}; border: ${this.tenantStyleGuide.content.buttonBorder}; font-weight: ${this.tenantStyleGuide.content.buttonFontWeight}; padding: ${this.tenantStyleGuide.content.buttonPadding || '10px'};`
        this.active = event.target.id
      } else {
        document.getElementById(event.target.id).style = `background: ${this.tenantStyleGuide.content.buttonBackgroundActive}; color: ${this.tenantStyleGuide.content.buttonFontActive}; font-family: ${this.tenantStyleGuide.content.fontFamilyPrimary}; letter-spacing: ${this.tenantStyleGuide.content.buttonLetterSpacing}; text-transform: ${this.tenantStyleGuide.content.buttonTextTransform}; border: ${this.tenantStyleGuide.content.buttonBorder}; font-weight: ${this.tenantStyleGuide.content.buttonFontWeight}; padding: ${this.tenantStyleGuide.content.buttonPadding || '10px'};`
        this.active = event.target.id
      }
      this.$emit('activeTab', event.target._value)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/_mixins"
@import "../../assets/_fonts"

.tab
  @include display-col
  padding: 20px 0

  &__header
    @include display-row
    justify-content: space-around
    overflow: hidden
    @include mobile-and-down
      overflow-x: auto
      justify-content: flex-start
        /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none
      scrollbar-width: none
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar
        display: none

    &--button
      width: 100%
      background: #fff

      &:hover
        cursor: pointer

</style>
