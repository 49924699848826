<template>
  <div class="scroll-bar" v-if="themeLoaded">
    <section>
      <div
        style="
          display: flex;
          flex-direction: row;
          margin: 15px 0;
          font-size: 14px;
        "
      >
        <div
          :style="{
            letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
            color: tenantStyleGuide.content.fontPrimary,
            marginRight: '10px',
          }"
        >
          {{ tenantMessages.walletExpirationDate.title }}:
        </div>
        <div
          :style="{
            letterSpacing: tenantStyleGuide.global.letterSpacing, fontFamily: tenantStyleGuide.content.fontFamilyPrimary,
            color: tenantStyleGuide.content.fontPrimary,
            marginRight: '10px',
          }"
        >
          <strong> {{ dateFormatter(user.balance_expires_on) }} </strong>
        </div>
        <Tooltip :text="tenantStyleGuide.wallet.tooltipMessage" :tenant-style-guide="tenantStyleGuide">
          <template v-slot:hover-item>
            <div
              v-html="svgIcon(tenantStyleGuide.icons.info)"
              :style="{ fill: tenantStyleGuide.content.fontPrimary }"
            />
          </template>
        </Tooltip>
      </div>
    </section>
    <WalletHeader
      :user-status="user.user_status"
      :balance-in-cents="user.balance_in_cents"
      :pending-balance-in-cents="user.pending_balance_in_cents"
      :expiration-balance-in-cents="userCashbackExpiration"
      :tenant-style-guide="tenantStyleGuide"
    />
    <section>
      <Tabs
        @active-tab="handleTableTypes"
        :tenant-style-guide="tenantStyleGuide"
        :tabsList="[
          {
            title: 'Status',
            value: 'status',
          },
          {
            title: 'Extrato',
            value: 'extrato',
          },
        ]"
      />
    </section>

    <div>
    </div>
    <section v-if="viewStatus === 'status'">
      <Tabs
        @active-tab="handleActiveTab"
        :tenant-style-guide="tenantStyleGuide"
        :tabsList="[
          {
            title: 'Tudo',
            value: '',
          },
          {
            title: 'Confirmado',
            value: 'fulfilled',
          },
          {
            title: 'Pendente',
            value: 'pending',
          },
          {
            title: 'Cancelado',
            value: 'canceled',
          },
          {
            title: 'Expiração',
            value: 'expiration',
          },
        ]"
      />
      <section v-if="viewExpiration">
        <TableBody :tenant-style-guide="tenantStyleGuide">
          <template v-slot:header>
            <TableHeader :table-data="expirationTableHeaders" :tenant-style-guide="tenantStyleGuide" />
          </template>
          <template v-slot:row>
            <TableRow
              v-for="expiration in expirationList"
              :key="expiration.expire_order_id"
              :tenant-style-guide="tenantStyleGuide"
              :table-data-column="[
                {
                  type: 'text',
                  value: expiration.expire_order_id,
                },
                {
                  type: 'text',
                  value: expiration.expire_cashback_program_ref_id,
                },
                {
                  type: 'currency',
                  value: expiration.expire_cashback_amount,
                },
                {
                  type: 'date',
                  value: expiration.expire_at || 'Never Expire',
                },
                {
                  type: 'status',
                  value: expiration.expire_status,
                },
                {
                  type: 'action',
                  value: `/operations/${expiration.expire_order_id}`,
                },
              ]"
            />
          </template>
        </TableBody>
        <Pagination
          style="margin-top: 20px"
          :current-page="expirationPagination.current_page"
          :per-page="expirationPagination.per_page"
          :total-entries="expirationPagination.total_entries"
          :total-pages="expirationPagination.total_pages"
          :tenant-style-guide="tenantStyleGuide"
          @change-page="handleExpirationPagination"
          @change-per-page="handleExpirationPerPage"
        />
      </section>
      <section v-else>
        <TableBody :tenant-style-guide="tenantStyleGuide">
          <template v-slot:header>
            <TableHeader :table-data="operationTableHeaders" :tenant-style-guide="tenantStyleGuide" />
          </template>
          <template v-slot:row>
            <TableRow
              v-for="operation in operationList"
              :key="operation.external_order_id"
              :tenant-style-guide="tenantStyleGuide"
              :table-data-column="[
                {
                  type: 'tag',
                  value: operation.type,
                },
                {
                  type: 'text',
                  value: operation.external_order_id,
                },
                {
                  type: 'currency',
                  value: operation.cashback_amount_in_cents,
                },
                {
                  type: 'currency',
                  value: operation.applied_balance_in_cents,
                },
                {
                  type: 'date',
                  value: operation.created_at,
                },
                {
                  type: 'status',
                  value: operation.status,
                },
                {
                  type: 'action',
                  value: `/operations/${operation.external_order_id}`,
                },
              ]"
            />
          </template>
        </TableBody>
        <Pagination
          style="margin-top: 20px"
          :current-page="operationPagination.current_page"
          :per-page="operationPagination.per_page"
          :total-entries="operationPagination.total_entries"
          :total-pages="operationPagination.total_pages"
          :tenant-style-guide="tenantStyleGuide"
          @change-page="handleOperationPagination"
          @change-per-page="handleOperationPerPage"
        />
      </section>
    </section>
    <section v-else>
      <Tabs
        @active-tab="handleTransactionsTypes"
        :tenant-style-guide="tenantStyleGuide"
        :tabsList="[
          {
            title: 'Tudo',
            value: '',
          },
          {
            title: 'Entrada',
            value: 'credit',
          },
          {
            title: 'Saída',
            value: 'debit',
          },
        ]"
      />
      <TableBody :tenant-style-guide="tenantStyleGuide">
        <template v-slot:header>
          <TableHeader :table-data="transactionsTableHeaders" :tenant-style-guide="tenantStyleGuide" />
        </template>
        <template v-slot:row>
          <TableRow
            v-for="transaction in transactionList"
            :key="transaction.expire_order_id"
            :tenant-style-guide="tenantStyleGuide"
            :table-data-column="[
              {
                type: 'status',
                value: transaction.type,
              },
              {
                type: 'currency',
                value: transaction.amount_in_cents,
              },

              {
                type: 'date',
                value: transaction.created_at || 'Sem Data',
              },
              {
                type: 'action',
                value: `/operations/${transaction.operation.external_order_id}`,
              },
            ]"
          />
        </template>
      </TableBody>
      <Pagination
        style="margin-top: 20px"
        :current-page="transactionPagination.current_page"
        :per-page="transactionPagination.per_page"
        :total-entries="transactionPagination.total_entries"
        :total-pages="transactionPagination.total_pages"
        :tenant-style-guide="tenantStyleGuide"
        @change-page="handleTransactionPagination"
        @change-per-page="handleTransactionPerPage"
      />
    </section>
  </div>
</template>

<script>

import decrypt from '../../utils/decrypt'
import dateFormatter from '../../utils/formatDate'
import convertBase64 from '../../utils/base64'

import DefaultTheme from '../../theme/default.json'
import BlankTheme from '../../theme/blank.json'
import DefaultMessages from '../../messages/default.json'

import { getWallet, getCashbackExpirationIn30Days } from '../../api/wallet'
import { getWalletTheme } from '../../api/tenantSettings'
import {
  getOperationsList,
  getExpirationList,
  getTransactionsList
} from '../../api/statement'

import Tabs from '../../components/Tabs'
import Tooltip from '../../components/Tooltip'
import TableRow from '../../components/Table/TableRow'
import TableBody from '../../components/Table/TableBody'
import Pagination from '../../components/Pagination'
import TableHeader from '../../components/Table/TableHeader'
import WalletHeader from '../../components/WalletHeader'

export default {
  name: 'MyWallet',
  components: {
    Tabs,
    Tooltip,
    TableRow,
    TableBody,
    Pagination,
    TableHeader,
    WalletHeader
  },
  data () {
    return {
      themeLoaded: false,
      tenantMessages: {},
      tenantStyleGuide: {},
      operationPagination: {},
      transactionPagination: {},
      expirationPagination: {},
      viewStatus: 'status',
      operationListQuery: {
        page: 1,
        limit: 10,
        status: ''
      },
      expirationListQuery: {
        page: 1,
        limit: 10
      },
      transactionListQuery: {
        page: 1,
        limit: 10,
        type: ''
      },
      user: {},
      info: {},
      viewExpiration: false,
      userCashbackExpiration: 0,
      operationList: [],
      transactionList: [],
      expirationTableHeaders: [
        'ID da compra',
        'Código do Produto',
        'Cashback',
        'Data de Expiração',
        'Status'
      ],
      operationTableHeaders: [
        'Tipo',
        'Id da Compra',
        'Cashback',
        'Saldo utilizado',
        'Criado Em',
        'Status'
      ],
      transactionsTableHeaders: ['Tipo', 'Cashback', 'Criado Em']
    }
  },
  created () {
    this.setData()
    this.tenantStyleGuide = BlankTheme
    this.tenantMessages = DefaultMessages
    this.getMyThemeColor()
    this.fetchData()
  },
  methods: {
    setData () {
      localStorage.setItem('info', decrypt(this.$route.params.info))
      this.info = JSON.parse(localStorage.getItem('info'))
    },
    async fetchData () {
      try {
        const { data } = await getWallet(
          this.info.externalRefId,
          this.info.apiKey
        )
        this.user = data.data
        this.fetchExpirationList()
        this.fetchCashbackExpiration()
        this.fetchOperationList()
        this.fetchTransactionList()
      } catch (error) {
        return true
      }
    },
    async fetchOperationList () {
      try {
        const { data } = await getOperationsList(
          this.info.externalRefId,
          this.info.apiKey,
          {
            page: this.operationListQuery.page,
            page_size: this.operationListQuery.limit,
            status:
              this.operationListQuery.status === ''
                ? undefined
                : this.operationListQuery.status,
            excluded_status:
              this.operationListQuery.status === ''
                ? 'denied'
                : undefined
          }
        )
        this.operationList = data.data
        this.operationPagination = data.pagination
      } catch (error) {
        return true
      }
    },
    handleOperationPagination (event) {
      this.operationListQuery.page = event
      this.fetchOperationList()
    },
    handleOperationPerPage (event) {
      this.operationListQuery.limit = event
      this.fetchOperationList()
    },
    handleActiveTab (event) {
      if (event === 'expiration') {
        this.viewExpiration = true
        this.fetchExpirationList()
      } else {
        this.viewExpiration = false
        this.operationListQuery.status = event
        this.operationListQuery.page = 1
        this.operationListQuery.limit = 10
        this.fetchOperationList()
      }
    },
    async fetchExpirationList () {
      try {
        const { data } = await getExpirationList(
          this.info.externalRefId,
          this.info.apiKey,
          {
            page: this.expirationListQuery.page,
            page_size: this.expirationListQuery.limit
          }
        )
        this.expirationList = data.data.cashbackToExpireData
        this.expirationPagination = data.pagination
      } catch (error) {
        return true
      }
    },
    async getMyThemeColor () {
      try {
        const { data } = await getWalletTheme(this.info.apiKey)
        console.log(data.data)
        this.tenantStyleGuide = { ...data.data, DefaultTheme }
        this.themeLoaded = true
      } catch {
        this.tenantStyleGuide = DefaultTheme
        this.themeLoaded = true
      }
    },
    handleExpirationPagination (event) {
      this.expirationListQuery.page = event
      this.fetchOperationList()
    },
    handleExpirationPerPage (event) {
      this.expirationListQuery.limit = event
      this.fetchOperationList()
    },
    async fetchTransactionList () {
      try {
        const { data } = await getTransactionsList(
          this.info.externalRefId,
          this.info.apiKey,
          {
            page: this.transactionListQuery.page,
            page_size: this.transactionListQuery.limit,
            type:
              this.transactionListQuery.type === ''
                ? undefined
                : this.transactionListQuery.type
          }
        )
        this.transactionList = data.data
        this.transactionPagination = data.pagination
      } catch (error) {
        return true
      }
    },
    handleTransactionPagination (event) {
      this.transactionListQuery.page = event
      this.fetchTransactionList()
    },
    handleTransactionPerPage (event) {
      this.transactionListQuery.limit = event
      this.fetchTransactionList()
    },
    handleTransactionsTypes (event) {
      this.transactionListQuery.type = event
      this.fetchTransactionList()
    },
    handleTableTypes (value) {
      this.viewStatus = value
    },
    async fetchCashbackExpiration () {
      try {
        const { data } = await getCashbackExpirationIn30Days(
          this.info.externalRefId,
          this.info.apiKey
        )
        this.userCashbackExpiration = data.data.reduce(
          (total, item) => parseInt(item.expire_cashback_amount) + total,
          0
        )
      } catch (error) {
        return true
      }
    },
    dateFormatter (value) {
      return dateFormatter.brazilianFormat(value)
    },
    svgIcon (value) {
      return convertBase64(value)
    }
  }
}
</script>
