import request from '../utils/request'

export function getOperationsList (externalRefId, apiKey, query) {
  return request({
    url: `/users/${externalRefId}/operations`,
    method: 'get',
    headers: {
      'x-api-key': apiKey
    },
    params: query
  })
}

export function getExpirationList (externalRefId, apiKey, query) {
  return request({
    url: `/cashback-expirations/${externalRefId}`,
    method: 'get',
    headers: {
      'x-api-key': apiKey
    },
    params: query
  })
}

export function getTransactionsList (externalRefId, apiKey, query) {
  return request({
    url: `/users/${externalRefId}/transactions`,
    method: 'get',
    headers: {
      'x-api-key': apiKey
    },
    params: query
  })
}

export default {
  getTransactionsList,
  getExpirationList,
  getOperationsList
}
